label[for=calleOrigen], label[for=calleDestino] {
  top: -14px;
  font-size: 12px;
  color: #5d9cec;
}

#calleOrigen .css-yk16xz-control, #calleOrigen .css-1hwfws3, #calleDestino .css-yk16xz-control, #calleDestino .css-1hwfws3 {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  background-color: #fbfbfb;
}
