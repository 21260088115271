.locality-selector thead, .locality-selector tbody { display: block; }

.locality-selector tbody {
  height: 220px;       /* Just for the demo          */
  overflow-y: auto;    /* Trigger vertical scroll    */
  overflow-x: hidden;  /* Hide the horizontal scroll */
}

.locality-selector tr{
  cursor: pointer;
}

.locality-selector > div {
  width: 105% !important;
}

table.locality-selector {
  width: 100%; /* Optional */
}

.locality-selector tbody td, .locality-selector thead th {
  width: 20%;  /* Optional */
}

.modal-loc-selector {
  max-width: 60% !important;
  width: 60% !important;
}

.add-localities {
  cursor: pointer;
}

.localities-list-form {
  float: left;
  margin-bottom: 3px;
}
