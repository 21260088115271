.modal-edit-street {
  padding-left: 0px;
}

.modal-edit-street > button {
  width: 28px;
  height: 34px;
  box-shadow: none !important;
  border: 1px solid #dde6e9;
  position: relative;
}

.modal-edit-street > button > i{
  position: absolute;
  top: 30%;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.text-modal-edit-street {
  font-size: 11px;
  text-align: justify;
}

.text-modal-edit-street > i {
  color: #b80d06;
}