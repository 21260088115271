.easypie-chart.easypie-chart-md span {
  font-size: 30px;
  margin-top: 3px;
  line-height: 40px;
}

.easypie-chart span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  margin-left: -50%;
  height: 30px;
  margin-top: -15px;
  font-size: 20px;
}

.card > div.slimScrollDiv, .card > div.slimScrollDiv > div.list-group {
  height: auto !important;
}

.detail-stats-box-data {
  margin-bottom: 4px !important;
  font-weight: bold;
}