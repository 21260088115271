$hl-color: #5d9cec;

.number-travel, .km-value, .travel-value {
  color: white;
  padding: 2px 6px 2px 6px;
  border-radius: 5px;
}

.km-error-value {
  background-color: #ff0000;
}

.km-approximated-value {
  background-color: #ff953e;
}

.km-center-value {
  background-color: #43ae5e;
}

.km-no-calculated {
  background-color: #afafaf;
}

.started-track {
  color: #6ed003;
}

.actions-selector {
  background-color: #e08e0b;
  color: white;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
  font-size: 12px;
}

.actions-selector:hover {
  color: white;
}

.link-change-state {
  color: white !important;
  padding: 6px 8px 6px 8px;
  border-radius: 5px;
  cursor: pointer;
}

.travel-toolbar {
  padding: 12px;
  font-size: 14px;
}

.travel-toolbar-icon {
  padding-right: 8px;
}

.company-selector {
  margin: 0px !important;
}

.company-selector > select {
  background: none !important;
  border: none;
  cursor: pointer;
}

.company-selector > select:focus {
  border: none !important;
}

.without-provider {
  background-color: $hl-color !important;
  color: white;
  border-radius: 5px !important;
  padding: 6px 6px 6px 10px;
}

.company-selector select:focus {
  border: none !important;
}

.item-prestador {
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex !important;
}

.css-2b097c-container {
 /* width: 100% !important;*/
}

.css-4ljt47-MenuList > div {
  cursor: pointer !important;
}

.summary-row {
  line-height: initial;
}

.summary-row .card-header {
  padding: .25rem !important;
}

.travel-actions-list {
  overflow: auto !important;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}

.badge-gray {
  background-color: #a9a9a9;
}

.travel-table {
  font-size: 0.85rem;
}

#state > option:not([disabled]) {
  font-weight: bold;
}

.travel-no-value {
  background-color: #ff0000;
}

.digital-voucher-generated {
  color: #43ae5e;
  font-size: 20px;
  cursor: pointer;
}

.digital-voucher-not-generated {
  color: #a9a9a9;
  font-size: 20px;
}

.travel-state-selector {
  max-width: 50% !important;
}

.digital-voucher > div > a{
  background-color: initial !important;
  border: none !important;
  padding: initial !important;
}

.datetimepicker-temporal-form {
  margin-top: 14px;
}

.company-custom-selector .css-2b097c-container {
  width: 90% !important;
}

.filter-item {
  padding-bottom: 8px;
}

.text-obs-art {
  margin-top: 83px;
}

.activate-filters {
  color: #1E983B;
}

.travel-state-selector form {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-obs-pre {
  margin-top: 65px;
}

.clear-filter {
  padding-left: 6px;
}

.travel-toolbar span {
  position: absolute;
  top: 14px;
}

ul.list-group > a.disabled {
  color: #6c757d9c !important;
}
