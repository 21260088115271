.error-item {
  font-size: 12px;
  padding-left: 10px;
  color: red;
  position: absolute;
  text-align: right;
  width: 95%;
  margin-top: -22px;
}

.street-selector-editor {
  margin-top: -28px;
}

.street-selector-editor > .group {
  margin-bottom: 0px !important;
}