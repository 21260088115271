th.sorting {
  &:hover {
    cursor: pointer;
  }

  &:after {
    font-family: "Font Awesome 5 Free", sans-serif;
    padding-left: 5px;
    content: "\F0D8\F0D7";
    color: rgba(0, 0, 0, 0.2);
  }

  &.asc:after {
    content: "\F0D8";
    color: inherit;
  }

  &.desc:after {
    content: "\F0D7";
    color: inherit;
  }

}

.search-action button {
  z-index: auto !important;
}

th .c-checkbox, td .c-checkbox {
  text-align: center !important;
}

th:first-child, td:first-child {
  text-align: center;
}