.color-input {
  width: 100%;
  height: 100%;
  border-radius: 1px;
}

.col-picker {
  padding-left: 0px;
}

.pick-color-container {
  padding: 4px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.pick-color-label {
  padding-left: 13px;
}

.row-color-input {
  width: 16px;
  height: 16px;
  border-radius: 1px;
}

.row-pick-color-container {
  padding: 4px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
  width: 25px;
  height: 100%;
}