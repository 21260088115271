$color-bg: #f5f7fa;
//Start Material Design
$bg-color: #424242;
$hl-color: #5d9cec;
$muted-color: mix(white, $bg-color, 70%);
$trans-time: 300ms;
$width: 100%;
$submit-color: #2B957A;

.body-bg-color {
  background-color: $color-bg;
}

.sidebar-nav {
  &.sidebar-subnav {
    li {
      margin-left: 15px;
    }
  }
}

.custom-card .float-right button {
  box-shadow: none !important;
}

.form-custom {
  margin: 0px !important;
}

.form-custom .card-title {
  border-bottom: 1px solid #eee;
}

.card-title span {
  margin-right: 5px;
}

.card .card-header .card-title {
  margin-bottom: 0;
  font-size: 1rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h1, h2, h3, h4 {
  font-weight: bold;
}

.form-card-mb-0 {
  margin-bottom: 0;
}

.form-card-pr-10 {
  padding-right: 10px;
}

.form-card-pl-10 {
  padding-left: 10px;
}

.form-card-mb-5 {
  margin-bottom: 5px;
}

.card-default {
  border-top-width: 1px;
}

.card-even {
  padding-right: 10px;
}

.card-odd {
  padding-left: 10px;
}

.custom-card-footer .row {
  margin-left: 0;
  flex-wrap: nowrap;
  margin-right: 0;
}

.custom-card-footer .col-company,
.custom-card-footer .col-group,
.custom-card-footer .col-user {
  padding: 10px 15px 3px;
  margin: 0 auto;
}

.card-default .card-header, .card-body {
  background-color: #fbfbfb;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}


.card-login {
  background-color: initial !important;
}

.login-form {
  margin: 0px !important;
}

.custom-card-footer .row {
  margin-left: 0;
  flex-wrap: nowrap;
  margin-right: 0;
}

.custom-card-footer .col-company,
.custom-card-footer .col-group,
.custom-card-footer .col-user {
  padding: 10px 15px 3px;
  margin: 0 auto;
}

.custom-card-footer .footer-desc .footer-icon {
  line-height: 0.2;
  font-size: 1.4em;
}


@media screen and (max-width: 767px) {
  .form-card-mb-0 {
    margin-bottom: 0;
  }

  .form-card-mb-5 {
    margin-bottom: 5px;
  }

  .card-even {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card-odd {
    padding-left: 15px;
    padding-right: 15px;
  }

  .custom-card {
    margin-bottom: 15px;
  }
}

form .btn {
  &.btn-submit:not(.btn-login):not(.btn-register) {
    &:active {
      background: darken(#2B957A, 5%) !important;
      color: mix(white, #2B957A, 85%) !important;
    }
  }
}

.form-group-custom {
  margin-bottom: 16px !important;
}

.form-control-custom {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  background-color: #fbfbfb;

  &:focus {
    background-color: #fbfbfb;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: inherit !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.form-control-custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0;
  padding: 0.375rem 1.75rem 0.375rem 1rem;

  &:disabled {
    color: $gray-700 !important;
    -webkit-text-fill-color: $gray-700 !important;
    opacity: 1;
    -webkit-opacity: 0.8;
  }
}

input[disabled], textarea[disabled],
select[disabled='disabled'] {
  color: #495057 !important;
  -webkit-text-fill-color: #495057 !important;
  opacity: 1;
  -webkit-opacity: 0.8 !important;
}

input:not(:focus)[value=""] ~ label {
  color: #495057 !important;
  -webkit-text-fill-color: #495057 !important;
  opacity: 1;
  -webkit-opacity: 0.8 !important;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.fit-cell-content {
  white-space: nowrap;
}

form {
  width: $width;
  margin: 45px auto;

  h1 {
    font-size: 3em;
    font-weight: 300;
    text-align: center;
    color: $hl-color;
  }
  h5 {
    text-align: center;
    text-transform: uppercase;
    color: $muted-color;
  }
  hr.sep {
    background: $hl-color;
    box-shadow: none;
    border: none;
    height: 2px;
    width: 25%;
    margin: 0px auto 45px auto;
  }
  .emoji {
    //font-size: 1.2em;
  }
}

.group {
  position: relative;
  margin: 25px 0;
}

// INPUTS // ============================== //
textarea {
  resize: none;
}

//Select2
.Select-control {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid $muted-color !important;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid $muted-color !important;
}

select,
input,
textarea {
  background: none;
  color: $muted-color;
  //font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: $width;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $muted-color;
  &:focus {
    outline: none;
  }
  &:focus ~ label:not(.label-per-page),
  &:valid ~ label:not(.label-per-page),
  &:disabled ~ label:not(.label-per-page) {
    top: -14px;
    font-size: 13px;
    color: $hl-color;
  }
  // Blink/WebKit/Edge
  &:read-only ~ label:not(.label-per-page) {
    top: -14px;
    font-size: 13px;
    color: $hl-color;
  }
  // Firefox
  &:-moz-read-only ~ label:not(.label-per-page) {
    top: -14px;
    font-size: 13px;
    color: $hl-color;
  }
  &:focus ~ .bar:before {
    width: $width;
  }
}

input[type="password"] {
  //letter-spacing: 0.3em;
}

//
//input:empty ~ label {
//    left: 5px;
//    top: 10px;
//    color: $muted-color;
//}

input:not(:focus)[value=""] ~ label {
  left: 5px;
  top: 10px;
  color: $muted-color;
}

form label {
  color: $muted-color;
  //font-size: 16px;
  font-weight: normal;
  position: absolute;
  //pointer-events: none;
  left: 5px;
  top: 10px;
  transition: $trans-time ease all;
}

.c-checkbox label {
  position: relative;
  top: inherit;
  color: #909FA7;
}

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $hl-color;
    transition: $trans-time ease all;
    left: 0%;
  }
}

// BUTTONS // ============================== //
form .btn {
  background: #fff;
  color: mix(black, $muted-color, 25%);
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  &:hover {
    color: mix(black, $muted-color, 30%);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
  }
  &.btn-link {
    background: $hl-color;
    color: mix(white, $hl-color, 80%);
    &:hover {
      background: darken($hl-color, 5%);
      color: mix(white, $hl-color, 85%);
    }
  }
  &.btn-submit {
    background: $submit-color;
    color: mix(white, $submit-color, 70%);
    &:hover {
      background: darken($submit-color, 5%);
      color: mix(white, $submit-color, 85%);
    }
  }
  &.btn-submit {
    background: $submit-color;
    color: mix(white, $submit-color, 70%);
    &:hover {
      background: darken($submit-color, 5%);
      color: mix(white, $submit-color, 85%);
    }
    &.btn-login,
    &.btn-register {
      background: $hl-color;
      color: mix(white, $hl-color, 70%);
      &:hover {
        background: darken($hl-color, 5%);
        color: mix(white, $hl-color, 85%);
      }
    }
  }
  &.btn-cancel {
    background: #eee;
    &:hover {
      background: darken(#eee, 5%);
      color: mix(black, $muted-color, 30%);
    }
  }
  &.btn-danger {
    background: #ed2d2d;
    color: white;
    &:hover {
      background: darken(#ed2d2d, 5%);
      color: mix(black, $muted-color, 30%);
      color: white;
    }
  }
}

.btn-box {
  text-align: center;
  margin: 50px 0;
}

//End Material Design

.owner-tag, .section-heading-icon {
  color: white;
  background-color: #0D82C5;
}

// Custom modal entity selector and date selector

.custom-modal-selector, .custom-date-selector, .theme-modal {
  .modal-header {
    background-color: #0D82C5;
    color: white;

    .close {
      color: inherit;
    }
  }
}

.custom-control-label {
  color: #656565 !important;
  font-size: .875rem !important;
  position: relative !important;
  margin-bottom: 0 !important;
  vertical-align: top !important;
}

.swal-footer {
  text-align: center !important;
}

ul.list-errors {
  padding: 0 15px;
}

.list-errors li {
  list-style: none;
}

.switch-input {
  height: 2.1875rem;
}

.group .switch {
  left: 0;
  top: 0;
}

.switch input:checked + span {
  background-color: #5d9cec;
  border-color: #5d9cec;
  transition: all .5s;
}

.switch.form-control {
  border-bottom: none;
}

select:hover, select > option:hover{
  cursor: pointer;
}

.form-group {
  font-size: 14px !important;
}

.search-button {
  z-index: initial !important;
}

.icon-active-stats {
  font-size: 25px;
  margin-left: 4px;
  margin-top: 5px;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}

.border-right {
  border-right: 1px solid #f4f4f4;
}

.description-block > .description-header {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.description-block > .description-text {
  text-transform: uppercase;
}

.description-percentage {
  text-align: center;
}

.description-header > span > i {
  padding-right: 6px;
}

.logo-footer > img {
  width: 2%;
}

.user-profile-icon {
  margin-top: 2px;
}

.btn-heading {
  margin-left: 5px !important;
}

.btn-add-right {
  position: absolute;
  right: 20px;
}

.btn-table-action {
  padding: 0.0625rem 0.3125rem !important;
  font-size: 0.8125rem !important;
  line-height: 1.5 !important;
  margin: 2px !important;
}

.item-permission {
  margin-right: 3px;
}

.control-sidebar-menu {
  list-style: none;
  display: block;
  padding: 10px 15px;
}

.control-sidebar-menu .menu-icon {
  float: left;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
}

.control-sidebar-menu .menu-info {
  margin-left: 45px;
  margin-top: 3px;
}

.control-sidebar-menu .menu-info > .control-sidebar-subheading {
  margin: 0;
}

.control-sidebar-menu a {
  text-decoration: none;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.link-sidebar {
  cursor: pointer;
}

.header-data {
  color: white;
  padding: 1rem .95rem;
}

.checkin-input .group {
  margin-bottom: 0px !important;
}

table {
  font-size: 0.85rem;
}

.filter-by-error {
  width: 200px !important;
}

.item-error {
  color: red;
}

.table th, .table td {
  padding: 5px 5px;
}

.whirl {
  position: static !important;
}

.whirl:before {
  background: none !important;
}

.whirl:before {
  background: none !important;
}

.whirl.ringed:after {
  border-right-color: #23b7e5 !important;
  border-top-color: #7266ba !important;
  border-left-color: #7266ba !important;
  border-bottom-color: #7266ba !important;
}

.loader-text {
  color: white;
  margin-left: 35%;
}

.import-right-text {
  font-size: 14px;
  top: 25px;
  line-height: 17px;
}

.import-count-travels {
  background: $hl-color;
  padding: 2px 6px 2px 6px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}