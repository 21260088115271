label[for=asegurado], label[for=empresa], label[for=sentido], label[for=permisos], label[for=provincia], label[for=rol], label[for=sentidoPisado],
label[for=tipo], label[for=zonaOrigen], label[for=zonaDestino], label[for=contrato], label[for=tipoContrato], label[for=vip],
label[for=import-state], label[for=import-company], label[for=localidadOrigen], label[for=localidadDestino], label[for=partido], label[for=localidad]
, label[for=prestador], label[for=cliente], label[for=street], label[for=portal], label[for=direccion], label[for=formaPago], label[for=marca],
label[for=modelo], label[for=chofer], label[for=consultorio], label[for=initYear], label[for=endYear], label[for=month]{
  top: -14px;
  font-size: 12px;
  color: #5d9cec;
}

.css-yk16xz-control:not(.filters-form), .css-1hwfws3:not(.filters-form),
#empresa .css-yk16xz-control:not(.filters-form), #empresa .css-1hwfws3:not(.filters-form),
#sentido .css-yk16xz-control:not(.filters-form), #sentido .css-1hwfws3:not(.filters-form),
#permisos .css-yk16xz-control:not(.filters-form), #permisos .css-1hwfws3:not(.filters-form),
#provincia .css-yk16xz-control:not(.filters-form), #rol .css-1hwfws3:not(.filters-form),
#sentidoPisado .css-yk16xz-control:not(.filters-form), #sentidoPisado .css-1hwfws3:not(.filters-form),
#tipo .css-yk16xz-control:not(.filters-form), #tipo .css-1hwfws3:not(.filters-form),
#zonaOrigen .css-yk16xz-control:not(.filters-form), #zonaOrigen .css-1hwfws3:not(.filters-form),
#zonaDestino .css-yk16xz-control:not(.filters-form), #zonaDestino .css-1hwfws3:not(.filters-form) {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  background-color: #fbfbfb;
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:active, .css-1pahdxg-control:focus, .css-1pahdxg-control:visited{
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  background-color: #fbfbfb;
}

div.css-1pahdxg-control {
  border-color: initial !important;
  box-shadow: initial !important;
  border-bottom-color: #5d9cec !important;
}

form .css-1hb7zxy-IndicatorsContainer {
  background-color: #fbfbfb !important;
}

.filter-form .css-yk16xz-control {
  border: 1px solid hsl(0,0%,80%) !important;
  border-radius: 5px !important;
}

.css-yk16xz-control {
  border-color: #dde6e9 !important;
}

.css-1fhf3k1-control {
  border: none !important;
  border-bottom: solid 1px hsl(0,0%,90%) !important;
  background-color: transparent !important;
  border-radius: initial !important;
}