.progress-number {
  float: right;
}

.progress-group {
  margin-bottom: 5px;
}

.bar-item {
  padding-bottom: 1rem;
}